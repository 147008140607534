<template>
  <v-card class="add-content-popup-card py-4 px-2">
    <v-card-title class="px-5 pt-4 pb-4">
      <div>Send Test Email</div>
    </v-card-title>
    <v-card-text class="pa-4">
      <div class="notification-menu__header px-3">
        Subject
      </div>
      <CustomTextInput
        v-model="subject"
        class="px-3"
      />
      <div class="notification-menu__header px-3">
        Preview Text
      </div>
      <CustomTextInput
        v-model="previewText"
        :maxlength="150"
        class="px-3"
      />
      <div class="notification-menu__header px-3">
        Recipients
      </div>
      <CustomCombobox
        v-model="recipients"
        :items="contactsList"
        class="px-3"
        dense
        chips
        small-chips
        multiple
        clearable
        item-value="id"
        item-text="name"
      />
      <div class="notification-menu__header px-3">
        From Line
      </div>
      <CustomDropdown
        v-model="fromLine"
        :items="fromLines"
        class="px-3"
        item-value="id"
        item-text="name"
      />
    </v-card-text>
    <v-card-actions class="d-flex justify-end align-center pa-0 mx-3">
      <div
        class="simple-text-button button--gray px-9"
        @click="$emit('dismiss')"
      >
        Cancel
      </div>

      <v-btn
        class="custom-button custom-button--blue px-15"
        height="34px"
        depressed
        @click="onSend"
      >
        Send
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import CustomTextInput from "@/sharedComponents/CustomTextInput";
import CustomDropdown from "@/sharedComponents/CustomDropdown";
import CustomCombobox from "@/sharedComponents/CustomCombobox";

export default {
  name: "SendTestEmailDialog",
  components: {CustomCombobox, CustomDropdown, CustomTextInput },
  props: {
    emailCreative: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      previewText: "",
      subject: "",
      fileName: "",
      fromLine: "",
      recipients: [],
      contactsList: [],
      fromLines: [],
    };
  },
  async mounted() {
    this.getFromLines();
    const { data } = await this.$rest.contact.getAllContacts({
      ignorePagination: true,
      isTest: true,
      groups: ['contact_default_email']
    });

    this.contactsList = data.items.map(item => {
      const contactItem = {
        id: item,
        name: `${item.firstName}${item.middleName ? ' ' + item.middleName : ''} ${item.lastName}`
      };

      if (item.defaultContactEmail) {
        contactItem.name += ` (${item.defaultContactEmail.emailAddress})`;
      }

      return contactItem;
    })
  },
  methods: {
    async onSend() {
      // send email
      try {
        const { data } = await this.$rest.creative.email.sendTestEmail({
          subject: this.subject,
          previewText: this.previewText,
          fileName: this.fileName,
          htmlContent: this.emailCreative.htmlContent,
          plainTextContent: this.emailCreative.plainTextContent,
          recipients: this.recipients?.map((recipient) => recipient.id.id) ?? [],
          fromLineId: parseInt(this.fromLine) || null,
        });

        this.fileName = data.fileName;

        this.$store.commit('snackbar/showMessage', {
          color: 'info',
          content: 'Test email sent!',
        });
        this.$emit('dismiss');
      } catch (e) {
        this.$store.commit('snackbar/showMessage', {
          color: 'error',
          content: 'Send test email failed. Please try again',
        });
      }
    },
    async getFromLines() {
      const { data } = await this.$rest.fromlines.get_collection({
        ignorePagination: true,
        fields: [
          'id',
          'fromName',
          'fromEmail',
        ]
      });

      this.fromLines = data.items.map(item => ({
        id: item.id,
        name: `${item.fromName} (${item.fromEmail})`
      }));
    }
  },
};
</script>

<style lang="scss" scoped>
.add-content-popup-card {
  border-radius: 5px !important;
}
</style>
